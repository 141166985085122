body {
  font-family: "Roboto", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gelasio", serif;
}
/* Header Animation css */
img {
  max-width: 100%;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: transform 400ms cubic-bezier(0.25, 0.37, 0.17, 0.96);
  z-index: 99999;
}

/* When header is not in viewport we hide it */
.header[data-scroll="out"] {
  transform: translatey(-400%);
}

/* When moving up and header we show it */
[data-scroll-dir-y="-1"] .header {
  transform: translatey(0);
}

.header {
  padding: 0;
  color: #222;
  background: #fff;
  box-shadow: 0 6px 8px 0 rgb(0 0 0 / 15%);
}

.header_logo {
  font-size: 27px;
  font-weight: 700;
  color: #262250;
}
.nav_parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0px;
}
.header .navbar-nav {
  margin-top: 4px;
}
.header .navbar-brand {
  padding: 0;
}
.hamburger_main.navbar-toggler {
  border: none;
}
.hamburger-react div:nth-child(3) {
  height: 6px;
}
body .offcanvas-backdrop.show {
  opacity: 0;
}
body .offcanvas.offcanvas-end {
  top: 0;
  width: 100%;
  left: -1px;
}

.header_logo img {
  max-width: unset;
  width: 195px;
  max-height: 42px;
}
.navbar-nav {
}
.navbar-nav a {
  color: #835797;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 4px;
  padding: 0 8px;
  text-decoration: none;
}
.navbar-nav a:hover {
  color: #fd7e14;
}
.header .navbar-nav .nav-link.active,
.header .navbar-nav .nav-link.show {
  color: inherit;
}
.offcanvas-body {
  text-align: center;
}
.offcanvas-body .navbar-nav a {
}
.get_startrd {
  display: inline-block;
  background: #fd7e14;
  color: #fff;
  padding: 16px 24px 16px 32px;
  margin: auto;
  border-radius: 8px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.4s ease;
}
.get_startrd:hover {
  background: #fd7e14;
}
.get_startrd svg {
  margin-left: 15px;
}
.get_startrd:hover svg {
  -webkit-animation: toRightFromLeft 0.3s forwards;
  -moz-animation: toRightFromLeft 0.3s forwards;
  animation: toRightFromLeft 0.3s forwards;
}
@-webkit-keyframes toRightFromLeft {
  49% {
    -webkit-transform: translate(100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate(-100%);
  }
  51% {
    opacity: 1;
  }
}
.banner_wrapper {
  padding: 9rem 0 0;
  background: #835797;
  color: #fff;
  text-align: center;
  min-height: 90vh;
  display: flex;
  align-items: center;
}
.banner_wrapper h5 {
  display: inline-block;
  border: 1px solid #fff6;
  padding: 14px 25px 10px;
  border-radius: 50px;
  background: #ffffff0f;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}
.banner_wrapper h5 svg {
  color: #fd7e14;
  margin: 0 12px 0 0;
}
.banner_wrapper h1 {
  font-weight: 700;
  font-size: 58px;
  line-height: 1.2;
  max-width: 880px;
  margin: 0 auto;
  margin-bottom: 25px;
  font-style: italic;
}
.banner_wrapper h1 span {
  color: #fd7e14;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}
.banner_wrapper h6 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 55px;
}
.banner_wrapper .bannerForm {
}
.bannerForm .form_fields {
  max-width: 320px;
  margin: 0 auto;
  text-align: left;
}
.pure-material-textfield-filled {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 1.5;
  /* overflow: hidden; */
  width: 100%;
  margin-bottom: 16px;
}
.pure-material-textfield-filled svg {
  position: absolute;
  top: 18px;
  left: 16px;
  font-size: 22px;
  color: #858585;
}

/* Input, Textarea */
.pure-material-textfield-filled > input {
  display: block;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  border-radius: 8px;
  padding: 22px 12px 0px 18px;
  width: 100%;
  height: 60px;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.04);
  background-color: #fff;
  font-family: inherit;
  font-size: 18px;
  font-weight: 600;
  color: #999;
  line-height: inherit;
  transition: border-bottom 0.2s, background-color 0.2s;
  border: 2px solid #fd7e14;
}

/* Span */
.pure-material-textfield-filled > input + span {
  position: absolute;
  top: 0;
  left: 7px;
  right: 0;
  bottom: 0;
  display: block;
  box-sizing: border-box;
  padding: 7px 12px 0;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  font-size: 100%;
  line-height: 18px;
  color: #fd7e14;
  pointer-events: none;
  transition: color 0.2s, font-size 0.2s, line-height 0.2s;
}

/* Underline */
.pure-material-textfield-filled > input + span::after,
.pure-material-textfield-filled > textarea + span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 2px;
  background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  transform-origin: bottom center;
  transform: scaleX(0);
  transition: transform 0.3s;
}

/* Hover */
.pure-material-textfield-filled > input:hover,
.pure-material-textfield-filled > textarea:hover {
  /* border-bottom-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87); */
  background-color: #fff;
  border-color: #00a6ba;
}
.pure-material-textfield-filled > input:hover + span + svg {
  color: #00a6ba;
}

/* Placeholder-shown */
.pure-material-textfield-filled > input:not(:focus):placeholder-shown + span,
.pure-material-textfield-filled
  > textarea:not(:focus):placeholder-shown
  + span {
  font-size: inherit;
  line-height: 48px;
  color: #737e80;
  font-size: 18px;
}
.pure-material-textfield-filled > input::placeholder {
  opacity: 0;
}
.pure-material-textfield-filled > input:focus::placeholder {
  opacity: 1;
  font-weight: 500;
}
/* Focus */
.pure-material-textfield-filled > input:focus,
.pure-material-textfield-filled > textarea:focus {
  outline: none;
  background: #fff;
  border-color: #fd7e14;
  /* box-shadow: 2px 2px 2px 0px #D2DFFA, -2px -2px 2px 0px #D2DFFA, -2px 2px 2px 0px #D2DFFA, 2px -2px 2px 0px #D2DFFA; */
}
.pure-material-textfield-filled > input:focus + span + svg {
  color: #00a6ba;
}
.pure-material-textfield-filled > input:focus + span,
.pure-material-textfield-filled > textarea:focus + span {
  /* color: rgb(var(--pure-material-primary-rgb, 33, 150, 243)); */
}

.pure-material-textfield-filled > input:focus + span::before,
.pure-material-textfield-filled > textarea:focus + span::before {
  opacity: 0.12;
}

.pure-material-textfield-filled > input:focus + span::after,
.pure-material-textfield-filled > textarea:focus + span::after {
  /* transform: scale(1); */
}

/* Disabled */
.pure-material-textfield-filled > input:disabled,
.pure-material-textfield-filled > textarea:disabled {
  border-bottom-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.24);
}

.pure-material-textfield-filled > input:disabled + span,
.pure-material-textfield-filled > textarea:disabled + span {
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
}

/* Faster transition in Safari for less noticable fractional font-size issue */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .pure-material-textfield-filled > input,
    .pure-material-textfield-filled > input + span,
    .pure-material-textfield-filled > input + span::after,
    .pure-material-textfield-filled > textarea,
    .pure-material-textfield-filled > textarea + span,
    .pure-material-textfield-filled > textarea + span::after {
      transition-duration: 0.1s;
    }
  }
}
.pure-material-textfield-filled .amount_hint {
  font-size: 14px;
  color: #fff;
  margin: 6px 0 0;
}
.form_submit {
  padding: 14px 24px 12px 32px;
  align-items: center;
  border-radius: 8px;
  background: #fd7e14;
  color: #fff;
  border: none;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  transition: 0.4s all ease;
}
.form_submit:hover {
  background: #0085ff;
}
.form_submit .submit_underline {
  text-decoration: underline;
}
.form_submit .submit_blur {
  opacity: 0.6;
}
.form_submit svg {
  transform: rotate(45deg);
}
.form_submit:hover svg {
}
.form_concent {
  text-align: center;
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 164%;
  letter-spacing: 0.13px;
  margin: auto;
  margin-bottom: 48px;
  max-width: 640px;
}
.form_concent span {
  color: #00a6ba;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
.banner_wrapper img {
  margin-bottom: 3.5rem;
}
.bannerpoints_wrapper {
  padding: 20px 0 20px;
}
.bannerpoints_container {
  display: flex;
  justify-content: space-between;
}
.bannerpoints_div {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  width: 25%;
}
.bannerpoints_div:nth-child(4) {
  /* width: 30%; */
}
.bannerpoints_div p {
  margin-bottom: 0;
  color: #504538;
  font-size: 17px;
}
.bannerpoints_div svg {
  color: #fd7e14;
  margin-right: 12px;
}
.whysection_wrapper {
  padding: 4rem 0 4rem;
  background: linear-gradient(
    90deg,
    rgba(255, 241, 255, 1) 0%,
    rgba(250, 244, 240, 1) 84%
  );
}
.whysection_container {
  margin-bottom: 25px;
}
.whysection_content {
  color: #504538;
}
.whysection_content h2 {
  font-size: 42px;
  font-weight: 600;
  font-style: italic;
  margin-bottom: 16px;
}
.whysection_content p {
  font-size: 18px;
  max-width: 440px;
  font-weight: 400;
  margin-bottom: 26px;
}
.whysection_content ul {
  padding-left: 0;
  list-style: none;
}
.whysection_content ul li {
  margin-bottom: 18px;
  font-size: 17px;
}
.whysection_content ul li svg {
  color: #fd7e14;
  margin-right: 14px;
}
.whysection_image {
}
.whysection_cta_div {
  text-align: center;
}
.hiw_wrapper {
  padding: 5rem 0 4rem;
}
.hiw_wrapper .hiw_head {
  text-align: center;
  margin-bottom: 70px;
}
.hiw_wrapper .hiw_head h2 {
  font-size: 42px;
  font-weight: 600;
  font-style: italic;
  margin-bottom: 16px;
  color: #504538;
}
.hiw_wrapper .hiw_head p {
  color: #736e6a;
  font-size: 18px;
}
.hiw_wrapper .hiw_div {
  max-width: 850px;
  margin: auto;
  margin-bottom: 40px;
}
.hiw_div_item .hiw_content {
  margin-left: 15%;
  /* display: flex;
  justify-content: center;
  flex-wrap: wrap; */
}
.hiw_div_item .hiw_content h4 {
  font-family: roboto;
  font-size: 24px;
}
.hiw_div_item .hiw_content p {
  color: #736e6a;
  font-size: 17px;
}
.hiw_div_item:nth-child(2) .hiw_content {
  margin-left: 0;
  margin-right: 10%;
  display: block;
}
.hiw_div_item .hiw_image {
  text-align: center;
}
.hiw_cta_div {
  text-align: center;
}
.eligsec_wrapper {
  padding: 5rem 0 4.5rem;
  background: #faf4f0;
}
.eligsec_image {
}
.eligsec_image img {
}
.eligsec_content {
}
.eligsec_content h2 {
  font-size: 44px;
  font-weight: 600;
  font-style: italic;
  margin-bottom: 36px;
  color: #504538;
}
.eligsec_content ul {
  padding-left: 0;
  list-style: none;
  margin-left: 8px;
}
.eligsec_content ul li {
  margin-bottom: 18px;
  padding-left: 26px;
  position: relative;
  font-size: 20px;
  color: #504538;
}
.eligsec_content ul li:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 10px;
  width: 10px;
  height: 10px;
  background: #504538;
  border-radius: 100%;
}
.eligsec_cta_div {
  text-align: center;
  margin-top: 80px;
}
.faq_container {
  padding: 4.5rem 0 4.5rem;
}
.faq_content {
}
.faq_content h3 {
  font-size: 44px;
  font-weight: 600;
  font-style: italic;
  margin-bottom: 36px;
  color: #504538;
}
.faq_image {
  text-align: center;
}
.faq_image img {
}
.faq_div {
  margin-bottom: 45px;
}
.faq_more_btn {
  background: #fff;
  border: 1px solid #ddd;
  padding: 10px 15px 8px;
  border-radius: 8px;
  color: #fd7e14;
  font-size: 18px;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 2px;
}
.faq_more_btn:hover {
}
.faq_more_btn svg {
  margin-left: 8px;
}
.accordion-item {
  border: none;
}
.accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
h2.accordion-header {
  border: none;
  font-family: roboto;
}
.accordion-button {
  display: flex;
  justify-content: space-between;
  border: none;
  padding-left: 0;
  color: #504538;
  font-size: 20px;
  font-weight: 600;
}
.accordion-button.collapsed {
  border-bottom: 1px solid #ddd;
}
.accordion-button:focus {
  box-shadow: none;
}
.accordion-button:not(.collapsed) {
  background: none;
}
.accordion-button::after {
  content: "\f067"; /* replace with your desired icon */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-left: 5px;
  background: none;
  color: #fd7e14;
}
.accordion-button:not(.collapsed)::after {
  content: "\f068";
  background: none;
  margin: 0;
  margin-left: 0px;
  width: 20px;
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: flex-end;
}
.accordion-collapse {
}
.accordion-body {
  border: none;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  font-size: 18px;
  color: #504538;
}
.accordion-collapse .accordion-body {
  /* border-bottom: 1px solid #ddd; */
}
.faq_more_btn {
}
.home_cta_wrapper {
  text-align: center;
  padding: 5rem 0 5rem;
  background: url(../public/static/assets/images/cta-bg.jpg) center;
  background-size: cover;
  /* background: linear-gradient(
    90deg,
    rgba(255, 241, 255, 1) 0%,
    rgba(250, 244, 240, 1) 84%
  ); */
}
.home_cta_wrapper h2 {
  font-size: 44px;
  font-weight: 600;
  font-style: italic;
  max-width: 850px;
  margin: auto;
  margin-bottom: 20px;
  color: #504538;
}
.home_cta_wrapper p {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 50px;
  color: #504538;
}
.home_cta_wrapper a {
}
footer.page_footer {
  background: #1b1b1e;
  color: #f9f8f37a;
  text-align: center;
  padding: 3rem 0 3rem;
}
.top_footer {
  max-width: 850px;
  margin: auto;
}
.top_footer img {
  margin-bottom: 32px;
}
.top_footer p {
  color: #fff;
  font-size: 18px;
  max-width: 600px;
  margin: auto;
  margin-bottom: 25px;
}
.top_footer .footer_img_wrapper img {
  margin: 0 12px 32px;
}
.top_footer ul {
  list-style: none;
  padding-left: 0;
}
.top_footer ul li {
  display: inline-block;
  padding: 0 15px;
  position: relative;
  margin-bottom: 14px;
}
.top_footer ul li:after {
  position: absolute;
  content: "|";
  right: 0;
  top: 0;
}
.top_footer ul li:last-child:after {
  content: "";
}
.top_footer ul li a {
  color: #f9f8f37a;
  text-decoration: none;
  font-size: 18px;
  transition: 0.4s all ease;
}
.top_footer ul li a:hover {
  color: #fff;
}
.bottom_footer {
}
.bottom_footer p {
  margin-bottom: 0;
}
.content_page_wrapper {
  padding: 7rem 0 5rem;
}
.page_breadcrumb {
  padding-left: 0;
  margin-bottom: 32px;
}
.page_breadcrumb li {
  display: inline-block;
  color: #212623;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
}
.page_breadcrumb li a {
  color: #222;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-decoration: underline;
}
.page_breadcrumb li svg {
  vertical-align: text-bottom;
}
.content_page_wrapper h1 {
  color: #212623;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 76.8px */
  letter-spacing: -1.5px;
  margin-bottom: 40px;
}
.content_page_wrapper p,
.content_page_wrapper li {
  color: #212623;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
}
.content_page_wrapper li {
  margin-bottom: 12px;
}
@media (max-width: 1199px) {
  .banner_wrapper h1 {
    font-size: 42px;
  }
  .banner_wrapper h6 {
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (max-width: 991px) {
  .offcanvas-body {
    padding: 10rem 0px 50px 0px;
    display: flex;
    align-items: flex-start;
    background: #fff;
  }
  .navbar-nav a {
    font-size: 30px;
    margin-bottom: 40px;
    font-weight: 400;
  }
  .hamburger_main {
    all: unset;
  }
  .banner_wrapper h1 {
  }
  .bannerpoints_container {
    flex-wrap: wrap;
  }
  .bannerpoints_div {
    width: 50%;
    margin-bottom: 25px;
  }
  .eligsec_wrapper {
    text-align: center;
  }
  .eligsec_content {
    text-align: left;
    margin-top: 30px;
  }
  .hiw_div_item {
    margin-bottom: 40px;
    text-align: center;
  }
  .hiw_div_item .hiw_content {
    margin-left: 0;
  }
  .hiw_div_item .hiw_image {
    margin-bottom: 25px;
  }
  .hiw_div_item_row_2 {
    flex-direction: column-reverse;
  }
  .hiw_div_item:nth-child(2) .hiw_content {
    margin-right: 0;
  }
  .faq_content {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 767px) {
  .banner_wrapper h1 {
    font-size: 30px;
  }
  .whysection_wrapper {
    padding: 2.5rem 0 2rem;
  }
  .whysection_content h2 {
    font-size: 32px;
  }
  .hiw_wrapper .hiw_head h2 {
    font-size: 32px;
  }

  .eligsec_content h2 {
    font-size: 32px;
  }
  .eligsec_content ul li {
    font-size: 18px;
  }
  .eligsec_cta_div {
    margin-top: 40px;
  }
  .faq_content h3 {
    font-size: 32px;
  }
  .home_cta_wrapper h2 {
    font-size: 32px;
  }
}
@media screen and (max-width: 576px) {
  .header_logo img {
    max-height: 45px;
  }
  .banner_wrapper h1 {
  }
  .banner_wrapper h5 {
    font-size: 16px;
    padding: 10px 20px 8px;
  }
  .banner_wrapper h6 {
    font-size: 18px;
  }
  .whysection_content ul li {
    display: flex;
  }
  .whysection_content ul li svg {
    min-width: 26px;
  }
  .content_page_wrapper h1 {
    font-size: 32px;
  }
  .hiw_wrapper {
    padding: 3rem 0 3rem;
  }
  .faq_container {
    padding: 3rem 0 3rem;
  }
  .eligsec_wrapper {
    padding: 3rem 0 3rem;
  }
  .home_cta_wrapper {
    padding: 3rem 0 3rem;
  }
  .top_footer img {
    max-width: 180px;
  }
}
@media screen and (max-width: 480px) {
  .bannerpoints_container {
    justify-content: center;
  }
  .bannerpoints_div {
    width: 100%;
    margin-bottom: 25px;
    text-align: center;
    justify-content: left;
    padding: 0 5% 0 4%;
  }
  .bannerpoints_div:nth-child(4) {
    margin-bottom: 0;
  }
  .nav_parent {
    padding: 2px 0px;
  }
  .header_logo img {
    width: 170px;
  }
}
@media screen and (max-width: 400px) {
  .banner_wrapper h1 {
    font-size: 26px;
  }
  .header_logo img {
    width: 150px;
  }
}
